<template>
  <div
    v-if="showCustomContent"
    v-expose="{ id: '1-19-1-131', data: customAnalysisData }"
    v-tap="{
      id: '1-19-1-132',
      data: customAnalysisData,
    }"
    class="order-custom-content"
    @click="openPreviewDialog"
  >
    <span class="order-custom-content__name">{{ language.SHEIN_KEY_PWA_26444 }}</span>
    <sui_icon_more_right_12px size="12px" :is-rotate="locals.GB_cssRight" />
  </div>
</template>
<script>
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import customDetailDialog from 'public/src/pages/common/customDetailDialog/index.js'
import { sui_icon_more_right_12px } from '@shein-aidc/icon-vue3'

export default defineComponent({
  name: 'CustomContent',
  directives: {
    expose,
    tap,
  },
  props: {
    language: {
      type: Object,
      default: () => {},
    },
    product: {
      type: Object,
      default: () => {},
    },
    customizationInfo: {
      type: [Object, String],
      default: () => {},
    },
  },
  components: {
    sui_icon_more_right_12px
  },
  computed: {
    ...mapState('orderDetail', [
      'locals'
    ]),
    customData() {
      let data = {}
      if (typeof this.customizationInfo === 'string') {
        try {
          data = JSON.parse(this.customizationInfo)
        } catch (e) {
          console.log(e)
        }
      } else if (this.customizationInfo && typeof this.customizationInfo === 'object') {
        data = this.customizationInfo
      }
      return data
    },
    showCustomContent() {
      const preview = this.customData.preview || {}
      return (
        preview?.effects?.length ||
        preview?.texts?.length ||
        preview?.images?.length
      )
    },
    customAnalysisData() {
      const preview = this.customData.preview || {}
      return {
        order_goods_id: this.product.goods_id,
        preview: preview?.effects?.length || 0,
        texts: preview?.texts?.length || 0,
        images: preview?.images?.length || 0,
      }
    },
  },
  methods: {
    openPreviewDialog() {
      const customInfo = this.customData.preview || {}
      customDetailDialog.open({
        ...customInfo,
      })
    },
  },
})
</script>

<style lang="less" scoped>
.order-custom-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12/75rem 8/75rem 12/75rem 12/75rem;
  font-family: 'SF UI Text';
  color: @sui_color_gray_dark1;
  background: @sui_color_gray_weak2;
  margin-top: 16/75rem;

  &__name {
    font-size: 12px;
    line-height: 14px;
    margin-right: 8/75rem;
  }
}
</style>
