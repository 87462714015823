<template>
  <div
    class="cod-split-package"
  >
    <s-alert
      key="CodSplitPackage"
      :max-rows="3"
      :load-more-txt="language.SHEIN_KEY_PWA_16133"
      @onLoadMoreClick="onLoadMoreClick"
    >
      {{ template(codSplitPackageLen, language.SHEIN_KEY_PWA_31509) }}
    </s-alert>
    <ClientOnly>
      <s-dialog
        v-if="showText"
        :show-close="false"
        :close-on-click-modal="true"
        v-model:visible="showText"
        :immediately-render="true"
        :append-to-body="true"
        class="ccc-notice-dialog"
      >
        <div
          class="notice-ctn"
          v-html="template(codSplitPackageLen, language.SHEIN_KEY_PWA_31509)"
        ></div>
        <template #footer>
          <s-button
            :type="['primary']"
            :width="'100%'"
            @click="showText = !showText"
          >
            {{ language.SHEIN_KEY_PWA_15312 }}
          </s-button>
        </template>
      </s-dialog>
    </ClientOnly>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { SAlert } from '@shein-aidc/sui-alert/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { template } from '@shein/common-function'
export default defineComponent({
  name: 'CodSplitPackage',
  components: {
    ClientOnly,
    SAlert,
    SButton,
    SDialog,
  },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    codSplitPackageLen: {
      type: Number,
      default: 0
    },
  },
  data () {
    return {
      showText: false
    }
  },
  methods: {
    template,
    onLoadMoreClick () {
      this.showText = !this.showText
    }
  }
})
</script>
<style lang="less">
.cod-split-package{
  padding: .21333333rem .32rem 0 .32rem;
  background: #fff;
  .sui-alert_background-info {
    background: #f6f6f6;
    padding: 16/75rem;
  }
  .sui-alert__description{
    color: #A86104;
  }
  .sui-alert__view-more{
    color: #A86104;
  }
}
</style>
