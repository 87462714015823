import schttp from 'public/src/services/schttp'

/**
 * 获取推荐信息
 * @param {*} param0 
 * @returns 
 */
export const getRecFacadeAtom = async (data) => {
  if (!data || !Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })

  try {
    return await schttp({
      method: 'POST',
      url: `/api/recommend/facadeAtom/get`,
      data,
      timeout: 10000
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}
