<template>
  <div
    v-if="labelText"
    class="large-time-label"
    :class="{ radius: !isStore, tab: isTab }"
  >
    <span class="large-time-label__text">
      {{ labelText }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  language: {
    type: Object,
    default: () => {}
  },
  type: {
    type: [String, Number],
    default: ''
  },
  text: {
    type: String,
    default: ''
  },
  isStore: {
    type: Boolean,
    default: false
  },
  isTab: {
    type: Boolean,
    default: false
  }
})

const labelText = computed(() => {
  if (props?.text) {
    return props?.text
  }
  if (props?.type) {
    if (props.type == 1) {
      return props.language.SHEIN_KEY_PWA_32176
    }
    return props.language.SHEIN_KEY_PWA_32175
  }
  return ''
})
</script>

<style lang="less" scoped>
.large-time-label {
  display: inline-block;
  padding: 2/75rem 6/75rem;
  position: relative;
  line-height: normal;
  text-shadow: none;
  letter-spacing: normal;
  background: @sui_color_gray_weak2;
  &__text {
    font-weight: 500;
    font-size: 10px;
    color: @sui_color_gray_dark3;
  }
}
.radius {
  border: 1px solid @sui_color_gray_light3;
  border-radius: 4/75rem;
}
.tab {
  padding: 0 4/75rem;
  font-size: 8px;
  border-radius: 0;
}
</style>
