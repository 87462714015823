<template>
  <div v-if="showAlert">
    <s-alert
      v-if="showAlert"
      :key="key"
      :type="type"
      :closeable="closable"
      :show-icon="showIcon"
    >
      <template 
        v-if="showIcon && type =='warning'" 
        #suffixIcon
      >
        <sui_icon_caution_18px
          size="16px"
          color="#BF4123"
          :custom-style="{ lineHeight: 1 }"
        />
      </template>
      {{ message }}
    </s-alert>
  </div>
</template>

<script>
import { SAlert } from '@shein-aidc/sui-alert/mobile'
import { sui_icon_caution_18px } from '@shein-aidc/icon-vue3'
import { defineComponent, nextTick } from 'vue'
export default defineComponent({
  name: 'AlertTip',
  components: {
    SAlert,
    sui_icon_caution_18px,
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'warning'
    },
    message: {
      type: String,
      default: ''
    },
    closable: {
      type: Boolean,
      default: true
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    rtlIconRoate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showAlert: false
    }
  },
  computed: {
    key() {
      let timer = new Date().getTime()
      return `${timer}_alert`
    }
  },
  watch: {
    show(val) {
      this.showAlert = false
      nextTick(() => {
        this.showAlert = !!val
      })
    }
  },
  mounted() {
    this.showAlert = !!this.show
  },
})
</script>
