<template>
  <div
    ref="timeContainer"
    class="time-container"
  >
    <slot :class-name="className"></slot>
    <div
      v-if="showHandleIcon"
      class="icon-container"
    >
      <i
        :class="inconClass"
        @click="handleText"
      ></i>
    </div>
  </div>
</template>
<script>
import { defineComponent, nextTick } from 'vue'
export default defineComponent({
  name: 'TextEllipsis',
  props: {
    isNeedShrink: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShrink: false,
      isExpanded: false,
      showHandleIcon: false
    }
  },
  computed: {
    className() {
      const classList = []
      if (this.isShrink) {
        if (this.isNeedShrink) {
          classList.push('over-large__text')
        } else {
          classList.push('over-text')
        }
      }
      if (this.isExpanded) {
        classList.push('expanded')
      }
      return classList.join(' ')
    },
    inconClass() {
      if (this.isExpanded) {
        return 'suiiconfont sui_icon_more_up_16px handle-icon '
      }
      return 'suiiconfont sui_icon_more_down_16px handle-icon'
    }
  },
  updated() {
    if (!this.showHandleIcon) {
      this.showMoreIcon()
    }
  },
  mounted() {
    if (!this.showHandleIcon) {
      this.showMoreIcon()
    }
  },
  methods: {
    handleText() {
      this.isExpanded = !this.isExpanded
      this.isShrink = !this.isShrink
      if (!this.isNeedShrink) {
        this.showHandleIcon = false
      }
    },
    showMoreIcon() {
      nextTick(() => {
        const container = this.$refs.timeContainer
        if (container && container.scrollHeight > container.clientHeight) {
          this.showHandleIcon = true
          this.isShrink = true
        }
      })
    }
  },
})
</script>
