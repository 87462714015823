<template>
  <div class="pay-now-swiper">
    <swiper-container
      ref="swiperRef"
      init="false"
      destroy-on-disconnected="false"
      class="pay-now-swiper__container"
      autoplay-delay="2800"
      :loop="payNowLabelList.length > 1"
    >
      <swiper-slide
        v-for="(item, index) in payNowLabelList"
        :key="index"
        class="swiper-slide-item"
        :class="{ 'swiper-slide-fade': payNowLabelList.length > 1 }"
      >
        <div class="unpaid-message-item">
          <div class="unpaid-message-item__icon">
            <img
              class="icon-img"
              :src="transformImg({ img: imageMap[item.inducementType] })"
            />
          </div>
          <span class="unpaid-message-item__text">
            {{ item.text }}
          </span>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { transformImg } from '@shein/common-function'
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'

if (typeof window !== 'undefined') {
  register()
}

export default defineComponent({
  name: 'PayNowSwiper',
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    payNowLabelList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    imageMap() {
      return {
        1: 'https://img.ltwebstatic.com/images3_ccc/2024/04/29/29/17143570011b4da0a09b784fc3e7c7041b25d8fd6d.png',
        2: 'https://img.ltwebstatic.com/images3_ccc/2024/04/29/04/1714357014e98fe10ea00d74aef8c29c06bf433ae8.png',
        3: 'https://img.ltwebstatic.com/images3_ccc/2024/04/29/db/1714357019b688ba4f459b0b55e339ccabb136d42c.png',
        4: 'https://img.ltwebstatic.com/images3_ccc/2024/04/29/cc/1714357022ac11e9a134255c140231ff9a557ec2b1.png',
        5: 'https://img.ltwebstatic.com/images3_ccc/2024/04/29/73/17143570259ce68bd14de5c0b912d57deab74a6314.png'
      }
    }
  },
  mounted() {
    this.initSwiper()
  },
  methods: {
    transformImg,
    initSwiper() {
      const swiperEl = this.$refs.swiperRef
      const options = {
        modules: [Autoplay],
        direction: 'vertical',
      }
      if (swiperEl) {
        Object.assign(swiperEl, options)
        swiperEl.initialize()
      }
    }
  },
})
</script>

<style lang="less" scoped>
.pay-now-swiper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  transform: translateY(-100%);
  direction: ltr !important;

  .swiper-slide-fade {
    transition: all 0.3s;
    opacity: 0.5;
  }

  :deep(.swiper-slide-active) {
    opacity: 1;
  }

  &__container {
    height: 60/75rem;
    overflow: hidden;
    background-color: @sui_color_welfare_bg;
  }

  .unpaid-message-item {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    position: relative;
    padding: 0 24/75rem;
    overflow: hidden;

    &__icon {
      position: relative;

      .icon-img {
        width: 36/75rem;
        height: 36/75rem;
      }
    }

    &__text {
      font-size: 12px;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: @sui_color_discount;
      margin-left: 8/75rem;
    }
  }
}
</style>
