<template>
  <div
    v-expose="{ id: scene == 'order_detail' ? '1-19-1-170': '1-30-1-74' }"
    class="content-wrapper"
  >
    <s-alert
      type="warning"
      show-icon
      :max-rows="2"
      :load-more-txt="language.SHEIN_KEY_PWA_36051"
      @onLoadMoreClick="onLoadMoreClick"
    >
      <template #suffixIcon>
        <Icon
          name="sui_icon_caution_18px"
          size="18px"
        />
      </template>
      {{ subsidyNoticeLink }}
    </s-alert>
    <ClientOnly>
      <s-dialog
        v-if="showText"
        :show-close="false"
        :close-on-click-modal="true"
        v-model:visible="showText"
        :immediately-render="true"
        :append-to-body="true"
        class="ccc-notice-dialog"
      >
        <template #title></template>
        <div
          ref="SubsidySpan"
          class="notice-ctn"
          v-html="subsidyNoticeLink"
        ></div>
        <template #footer>
          <s-button
            :type="['primary']"
            :width="'100%'"
            @click="showText = !showText"
          >
            {{ language.SHEIN_KEY_PWA_15312 }}
          </s-button>
        </template>
      </s-dialog>
    </ClientOnly>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { SubsidyJumpSite } from 'public/src/pages/user/child_pages/orders/common/constants.js'
import { template } from '@shein/common-function'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SAlert } from '@shein-aidc/sui-alert/mobile'
import { Icon } from '@shein-aidc/icon-vue3'


daEventCenter.addSubscriber({ modulecode: '1-19-1' })
daEventCenter.addSubscriber({ modulecode: '1-30-1' })
export default defineComponent({
  name: 'SubsidyAlert',
  directives: {
    expose,
  },
  components: {
    ClientOnly,
    SDialog,
    SAlert,
    SButton,
    Icon
  },
  props: {
    scene: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    language: {
      type: Object,
      default: ()=>({})
    }
  },
  data() {
    return {
      isExpanded: false,
      showText: false,
    }
  },
  computed: {
    subsidyNoticeLink() {
      if (typeof gbCommonInfo !== 'undefined') {
        let lang  = gbCommonInfo?.SiteUID
        return template(SubsidyJumpSite[lang] || SubsidyJumpSite['default'], this.text)
      }
      return template(SubsidyJumpSite['default'], this.text)
    },
  },
  mounted() {
  
  },
  methods: {
    onLoadMoreClick () {
      this.showText = !this.showText
      this.bindSubsidyNotice()
    },
    bindSubsidyNotice() {
      setTimeout(() => {
        this.$refs?.SubsidySpan?.childNodes?.[1]?.addEventListener?.('click', this.exposeSubsidyNotice)
      })
    },
    exposeSubsidyNotice() {
      daEventCenter.triggerNotice({
        daId: this.scene == 'order_detail' ? '1-19-1-171' : '1-30-1-75',
      })
    },
  }
})
</script>
