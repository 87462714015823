<template>
  <div class="logistics-steps">
    <div
      v-for="(item, index) in logisticsData"
      :key="item.id"
      class="logistics-step"
      :class="{ 'is-active': active === index }"
    >
      <div class="logistics-step__head">
        <div
          class="logistics-step__line"
          :class="{ 'is-finish': active >= index }"
        ></div>
        <div
          v-if="index < active - 1 || (index === active - 1 && selectedEnd)"
          class="logistics-step__line-solid"
          :class="{ 'is-animation': active - 1 === index }"
          @animationend="lineAnimationEnd"
        ></div>
        <div class="logistics-step__icon">
          <div
            v-if="index === active && !playEnd"
            :ref="`animationIcon${active}`"
            class="logistics-step__icon-animation"
            :style="{ backgroundImage: `url(${item.iconActiveBg})` }"
          ></div>
          <img
            v-else
            :class="{ 'is-active': active === index }"
            class="logistics-step__icon-img"
            :src="getStepSrc(item, index)"
          />
          <img
            v-if="index < active"
            class="logistics-step__icon-selected"
            :class="{ 'is-animation': active - 1 === index }"
            :src="`${locals.PUBLIC_CDN}/pwa_dist/images/orders/sui_icon_select_img_selected@2x-963e27d1f2.png`"
            @animationend="selectedAnimationEnd"
          />
        </div>
      </div>
      <div
        class="logistics-step__title"
        :class="{
          'is-finish': active > index,
          'is-highlight':
            (active === 0 && index === 0) || (active === index && playEnd)
        }"
      >
        {{ getTrackStatusCccText(index) || item.title }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import Vap from 'video-animation-player'
import vapPreparingConfig from 'public/pwa_dist/vap/orders/preparing/vapc.json'
import vapShippedConfig from 'public/pwa_dist/vap/orders/shipped/vapc.json'
import vapDeliveringConfig from 'public/pwa_dist/vap/orders/delivering/vapc.json'
import vapDeliveredConfig from 'public/pwa_dist/vap/orders/delivered/vapc.json'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'LogisticsSteps',
  props: {
    trackInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    language: {
      type: Object,
      default: function () {
        return {}
      }
    },
    orderStatusText: {
      type: String,
      default: ''
    },
    isProcessing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedEnd: false,
      playEnd: false,
      active: 0
    }
  },
  computed: {
    ...mapState('orderDetail', [
      'locals'
    ]),
    logisticsData() {
      const publicCdn = this.locals.PUBLIC_CDN || ''
      return [
        {
          id: 1,
          title: this.language.SHEIN_KEY_PWA_22703,
          iconDone: `${publicCdn}/pwa_dist/images/orders/sui_img_preparing_done@2x-e9c48e5357.png`,
          iconActive: `${publicCdn}/pwa_dist/images/orders/sui_img_preparing@2x-c8f20bfbfc.png`,
          iconActiveBg: `${publicCdn}/pwa_dist/images/orders/sui_img_preparing_disable_bg@2x-98002bf95f.png`,
          videoUrl: `${publicCdn}/pwa_dist/vap/orders/preparing/video.mp4`,
          videoConfig: vapPreparingConfig
        },
        {
          id: 2,
          title: this.language.SHEIN_KEY_PWA_15213,
          iconDone: `${publicCdn}/pwa_dist/images/orders/sui_img_shipped_done@2x-fd7ff71923.png`,
          iconActive: `${publicCdn}/pwa_dist/images/orders/sui_img_shipped@2x-3ac8a9a1ea.png`,
          iconActiveBg: `${publicCdn}/pwa_dist/images/orders/sui_img_shipped_disable_bg@2x-c5380e80ed.png`,
          iconDisabled: `${publicCdn}/pwa_dist/images/orders/sui_img_shipped_disable@2x-127e90ceb9.png`,
          videoUrl: `${publicCdn}/pwa_dist/vap/orders/shipped/video.mp4`,
          videoConfig: vapShippedConfig
        },
        {
          id: 3,
          title: this.language.SHEIN_KEY_PWA_24302,
          iconDone: `${publicCdn}/pwa_dist/images/orders/sui_img_delivering_done@2x-af7bdc998d.png`,
          iconActive: `${publicCdn}/pwa_dist/images/orders/sui_img_delivering@2x-fe157057ce.png`,
          iconActiveBg: `${publicCdn}/pwa_dist/images/orders/sui_img_delivering_disable_bg@2x-d72b901b57.png`,
          iconDisabled: `${publicCdn}/pwa_dist/images/orders/sui_img_delivering_disable@2x-35efc1c424.png`,
          videoUrl: `${publicCdn}/pwa_dist/vap/orders/delivering/video.mp4`,
          videoConfig: vapDeliveringConfig
        },
        {
          id: 4,
          title: this.language.SHEIN_KEY_PWA_24301,
          iconActive: `${publicCdn}/pwa_dist/images/orders/sui_img_delivered@2x-570332bb6a.png`,
          iconActiveBg: `${publicCdn}/pwa_dist/images/orders/sui_img_delivered_disable_bg@2x-bc14aea2d2.png`,
          iconDisabled: `${publicCdn}/pwa_dist/images/orders/sui_img_delivered_disable@2x-96f7be8e64.png`,
          videoUrl: `${publicCdn}/pwa_dist/vap/orders/delivered/video.mp4`,
          videoConfig: vapDeliveredConfig
        }
      ]
    },
    initActive () {
      let statusMap = {
        已下单: 0,
        已发货: 1,
        运输中: 1,
        派送中: 2,
        待取件: 2,
        签收: 3,
        default: 0
      }
      let mall_status = this.trackInfo?.mall_status

      return statusMap[mall_status] || statusMap.default
    }
  },
  watch: {
    initActive() {
      nextTick(() => {
        this.initActiveEvt()
      })
    }
  },
  mounted() {
    this.initActiveEvt()
  },
  methods: {
    initActiveEvt () {
      this.vap?.destroy()
      this.playEnd = false
      this.active = this.initActive

      if (this.active === 0) {
        nextTick(() => {
          this.initIconAnimation()
        })
      }
    },
    getStepSrc(item, index) {
      if (this.active === index) {
        return item.iconActive
      } else if (this.active > index) {
        return item.iconDone
      } else {
        return item.iconDisabled
      }
    },
    //勾选动画结束
    selectedAnimationEnd() {
      this.selectedEnd = true
    },
    //实线动画结束
    lineAnimationEnd() {
      this.initIconAnimation()
    },
    initIconAnimation() {
      let dom = this.$refs[`animationIcon${this.active}`]
      if (Array.isArray(dom)) dom = dom[0]
      const current = this.logisticsData[this.active] || {}
      this.vap = new Vap({
        container: dom,
        src: current.videoUrl,
        config: current.videoConfig,
        width: 92,
        height: 92,
        fps: 25,
        beginPoint: 0,
        mute: true,
        accurate: true,
        onLoadError: () => {
          this.playEnd = true
        }
      })
      // 如果无法播放视频直接显示已完成状态
      let timer = setTimeout(() => {
        this.playEnd = true
        this.vap = null
      }, 1000)
      this.vap
        .on('ended', () => {
          this.playEnd = true
          this.vap?.destroy()
          this.vap = null
        })
        .on('frame', () => {
          if (timer) {
            clearTimeout(timer)
            timer = null
          }
        })
      this.vap?.play()
    },
    getTrackStatusCccText (index) {
      if (!this.orderStatusText) return ''
      if (
        ([2, 3].includes(this.trackInfo?.mall_status_code)
        || this.isProcessing)
        && this.active === index
      ) {
        return this.orderStatusText
      }
      return ''
    }
  },
})
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type,  selector-nested-pattern  */
@small-size: 30px;
@large-size: 46px;
@success-color: #198055;
@bg: linear-gradient(180deg, #f5fcfb 0%, rgba(245, 252, 251, 0) 100%), #fff;

.line-common() {
  position: absolute;
  top: 50%;
  left: 50%;
  right: -50%;
  transform: translateY(-50%);
}

@keyframes logisticsStepLine {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}

@keyframes logisticsZoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 1;
  }
}

.logistics-steps {
  display: flex;
  padding: 24/75rem;
  background: @bg;
  box-sizing: border-box;
}

.logistics-step {
  display: inline-block;
  position: relative;
  flex-shrink: 1;
  flex-basis: 25%;

  &:last-of-type &__line {
    display: none;
  }

  &:last-of-type &__line-solid {
    display: none;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    min-height: @large-size;
  }

  &__head,
  &__title {
    text-align: center;
  }

  &__line {
    .line-common();
    border-bottom: 2px dashed #e5e5e5;
  }

  &__line-solid {
    .line-common();
    height: 2px;
    background: @success-color;

    &.is-animation {
      animation: logisticsStepLine 0.5s linear forwards;
    }
  }

  &__icon {
    position: relative;
    z-index: 1;
    max-width: @large-size;
    max-height: @large-size;
    background: @bg;

    &-img {
      width: @small-size;
      height: @small-size;

      &.is-active {
        width: @large-size;
        height: @large-size;
      }
    }

    &-animation {
      width: @large-size;
      height: @large-size;
      background-size: contain;
      background-repeat: no-repeat;

      canvas {
        width: @large-size;
        height: @large-size;
      }
    }

    &-selected {
      position: absolute;
      right: 1px;
      bottom: -1px;
      width: 10px;
      height: 10px;

      &.is-animation {
        animation: logisticsZoomIn 0.5s linear forwards;
      }
    }
  }

  &__title {
    font-size: 12px;
    color: #bbbbbb;
    word-break: break-all;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;

    &.is-finish {
      color: @success-color;
    }

    &.is-highlight {
      font-weight: bold;
      color: @success-color;
    }
  }
}
</style>
