<template>
  <s-drawer
    :visible="show"
    max-size="89%"
    :show-close="true"
    :append-to-body="true"
    class="custom-detail-dialog"
    @close-from-icon="closeByIcon"
    @close-from-mask="closeByMask"
  >
    <template #title>
      <div class="header-title">{{ language.SHEIN_KEY_PWA_26444 }}</div>
    </template>
    <div class="content">
      <div class="custom-content-tips">
        <sui_icon_caution_18px
          class="custom-content-tips__icon"
          size="14px"
        />
        <span
          class="custom-content-tips__text"
          v-html="language.SHEIN_KEY_PWA_37232"
        >
        </span>
      </div>
      <template v-if="effects.length > 0">
        <div class="block-title">{{ language.SHEIN_KEY_PWA_26441 }}:</div>
        <div class="block-content">
          <div
            v-for="(item, index) in effects"
            :key="item+index"
            class="preview-img"
            @click="viewBigPicture('effects', index, item)"
          >
            <img
              class="lazyload img-content"
              :src="LAZY_IMG"
              :data-src="dprInsert({ img: item })"
              alt="shein picture"
            />
          </div>
        </div>
      </template>

      <template v-if="texts.length > 0">
        <div class="block-title">{{ language.SHEIN_KEY_PWA_26446 }}:</div>
        <div class="block-content">
          <div
            v-for="(text, index) in texts"
            :key="text+index"
            class="text-content"
          >
            {{ text }}
          </div>
        </div>
      </template>

      <template v-if="images.length > 0">
        <div class="block-title">{{ language.SHEIN_KEY_PWA_26442 }}:</div>
        <div class="block-content">
          <div
            v-for="(image, index) in images"
            :key="image+index"
            class="custom-img"
            @click="viewBigPicture('images', index, image)"
          >
            <img
              class="lazyload img-content"
              :src="LAZY_IMG"
              :data-src="dprInsert({img: image })"
              alt="shein picture"
            />
          </div>
        </div>
      </template>
    </div>
    <div class="foot">
      <s-button 
        :type="['primary', 'H40PX']"
        :width="'100%'"
        @click="close"
      >
        {{ language.SHEIN_KEY_PWA_26443 }}
      </s-button>
    </div>
  </s-drawer>
</template>

<script>
import mitt from 'mitt'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { defineComponent } from 'vue'
import CustomDaEvent from './utils/analysis.js'
import { dprInsert } from './utils/tools.js'
import { sui_icon_caution_18px } from '@shein-aidc/icon-vue3'

const eventBus = mitt()

export default defineComponent({
  name: 'CustomDetail',
  components: {
    SButton,
    SDrawer,
    sui_icon_caution_18px
  },
  emits: ['close', 'closeByIcon', 'closeByMask'],
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    previewInstance: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showDetailDialog: false,
      texts: [],
      effects: [],
      images: [],
      previewStatus: {
        show: false
      },
      analysisData: {},
      LAZY_IMG: LAZY_IMG,
    }
  },
  computed: {
    show: {
      get() {
        return this.showDetailDialog
      },
      set(val){
        if(!val) this.$emit('close')
        this.showDetailDialog = val
        if(!this.showDetailDialog) {
          eventBus.off()
          CustomDaEvent.click_custom_content_close()
          this.texts = []
          this.effects = []
          this.images = []
        }
      }
    },
  },
  methods: {
    open(ctx) {
      this.initData(ctx)
      this.show = true
      // 曝光埋点
      CustomDaEvent.expose_custom_content({
        images: this.images?.length || 0,
        preview: this.effects?.length || 0,
        texts: this.texts?.length || 0,
      }, this.analysisData)
    },
    close() { // 外部触发关闭
      this.show = false
    },
    closeByIcon() { // icon触发关闭
      this.show = false
      this.$emit('closeByIcon')
    },
    closeByMask() { // mask触发关闭
      this.show = false
      this.$emit('closeByMask')
    },
    initData(ctx) {
      const { texts, effects, images, onEvent, analysisData = {} } = ctx || {}
      this.texts = texts || []
      this.effects = effects || []
      this.images = images || []
      this.analysisData = analysisData
      if(onEvent) {
        Object.keys(onEvent).forEach(key => {
          eventBus.on(key, onEvent[key])
        })
      }
    },
    dprInsert,
    viewBigPicture(type, index, url) {
      this.previewInstance.open({
        previewStatus: {
          type,
          index,
          url
        },
        effects: this.effects,
        images: this.images
      })
      CustomDaEvent.click_custom_content_imagedetail()
    },
    closePreviewPicture(val){
      this.previewStatus.show = val
    }
  },
})
</script>

<style scoped lang="less">
.custom-detail-dialog {
  :deep(.icons-nav_close) {
    font-size: 16px!important;
  }
}
.header-title {
  height: 80/75rem;
  padding: 18/75rem 0;
  font-size: 14px;
  color: #767676;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  padding: 16/75rem 24/75rem 16/75rem;
  overflow-y: auto;
}
.foot {
  padding: 16/75rem 24/75rem 16/75rem;
}
.custom-content-tips{
  color: #959595;
  font-size: 12px;
  line-height: normal;
  margin-bottom: 32/75rem;
  position: relative;
  &__icon{
    position: absolute;
    .left(0);
    top: 0;
  }
  &__text{
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-indent: 32/75rem;
  }
}
.block-title {
  font-size: 12px;
  font-weight: 700;
  color: #767676;
  margin-bottom: 16/75rem;
}
.block-content {
  display: flex;
  flex-wrap: wrap;
  gap: 8/75rem;
  margin-bottom: 48/75rem;
  .img-content {
    object-fit: contain;
    display: block;
    width: 100%;
    height: 100%;
  }
  .preview-img {
    width: 122/75rem;
    height: 122/75rem;
    position: relative;
    overflow: hidden;
  }
  .text-content {
    background: #F6F6F6;
    padding: 12/75rem 24/75rem;
    color: #666;
  }
  .custom-img {
    width: 134/75rem;
    height: 134/75rem;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
}
</style>
