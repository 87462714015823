<template>
  <div>
    <SLazyMount>
      <s-dialog
        v-model:visible="cancelExchangeTip"
        :show-close="false"
        append-to-body
        @close="cancelExchangeTip = false"
      >
        <template #title>
          <div class="split-package-tip">
            {{ language.SHEIN_KEY_PWA_17517 }}
          </div>
        </template>
        <template #footer>
          <s-button-group
            hor
            :width="'100%'"
            :num="2"
          >
            <s-button-group-item @click="cancelExchangeEvt">
              {{ language.SHEIN_KEY_PWA_15252 }}
            </s-button-group-item>
            <s-button-group-item
              :type="['primary']"
              @click="cancelExchangeTip = false"
            >
              {{ language.SHEIN_KEY_PWA_15253 }}
            </s-button-group-item>
          </s-button-group>
        </template>
      </s-dialog>
    </SLazyMount>

    <!-- 整单退关联商品一起退 -->
    <SLazyMount mount-prop-name="show">
      <cancelRelationGoodItem
        :show="showRelationDialog"
        :relationGoodsList="relationGoodsList"
        :relationOptions="relationOptions"
        :language="language"
        :exposeCombinedRefundTip="exposeCombinedRefundTip"
        @confirmCancelSubmit="confirmCancelSubmit"
        @updateShowValue="updateShowValue"
      />
    </SLazyMount>

    <!-- 自主标记确认弹窗 -->
    <s-lazy-mount>
      <ResumeShipmentDialog
        v-model:visible="showResumeShipmentDialog"
        :language="language"
        :async-callbacks="asyncCallbacks"
        :info="resumeShipmentInfo"
        :order-info="orderInfo"
      />
    </s-lazy-mount>

    <!-- 已支付未发货订单单件退 -->
    <cancelItemComp />
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import schttp from 'public/src/services/schttp'
import { mapMutations, mapState } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter'
import cancelRelationGoodItem from 'public/src/pages/user/child_pages/orders/list/cancelRelationGoodItem.vue'
import orderDetailStoreModule from 'public/src/pages/product_app/store/modules/user/order_detail.js'
import cancelItemStoreModule from 'public/src/pages/product_app/store/modules/user/order-cancel-item'
import cancelItemComp from 'public/src/pages/user/child_pages/orders/common/cancelItem/index.vue'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButtonGroup } from '@shein-aidc/sui-button-group/mobile'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item/mobile'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import ResumeShipmentDialog from 'public/src/pages/user/child_pages/orders/common/ResumeShipmentDialog.vue'

export default defineComponent({
  components: {
    cancelRelationGoodItem,
    cancelItemComp,
    SLazyMount,
    SButtonGroupItem,
    SButtonGroup,
    SDialog,
    ResumeShipmentDialog
  },
  props: {
    language: {
      type: Object,
      default() {
        return {}
      },
    },
    orderInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    cancelReason: {
      type: Number,
      default: 0
    },
    abtInfo: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      currentProduct: {},
      cancelExchangeTip: false,
      showRelationDialog: false,
      relationGoodsList: [],
      relationOptions: {
        type: '',
        url: '',
        billno: '',
        cancelInfo: {},
        operatorType: 'full'
      },
      resumeShipmentInfo: {},
      showResumeShipmentDialog: false,
      asyncCallbacks: [],
    }
  },
  computed: {
    ...mapState('root', ['rootStatus']),
    ...mapState('cancelItemModule', ['refundForm']),
  },
  created() {
    this.handleStore()
  },
  methods: {
    ...mapMutations('orderDetail', ['showMsg']),
    ...mapMutations('root', ['changeRootStatus']),
    ...mapMutations('cancelItemModule', ['assignCancelItemState']),
    handleStore() {
      const store = this.$store
      if (!store.state['orderDetail']) store.registerModule('orderDetail', orderDetailStoreModule)
      if (!store.state['cancelItemModule']) store.registerModule('cancelItemModule', cancelItemStoreModule)
    },
    cancelGoodsEvt(product) {
      this.currentProduct = product
      this.cancelExchangeTip = true
    },
    async cancelExchangeEvt() {
      const billno = this.orderInfo.billno
      const id = this.currentProduct.id
      let res = await schttp({
        method: 'POST',
        url: '/api/orders/base/getUnionRevokeRefund/query',
        data: {
          list: JSON.stringify([
            {
              billno,
              order_goods_ids: [id],
              refund_path: 1,
              is_current: 1,
              cancel_reason: !!this.cancelReason ? this.cancelReason : 9,
              reason: 5
            }
          ])
        },
        headers: { blackbox: window._fmOpt?.__blackbox || '' }
      })
      this.cancelExchangeTip = false
      let hasOwnOrderGoods = res?.info?.success_list?.filter(item => item.billno == billno)
      if (res.code == '0' && hasOwnOrderGoods?.length) {
        // 取消订单需要触发homeCccxCouponUpdate事件，首页监听这个事件去更新劵包信息
        appEventCenter.emit('homeCccxCouponUpdate')
        const currentOrderInfo = res?.info?.order_info_list?.find(item => item?.billno == billno) || {}
        if (this.cancleOutOfStockResumeShipment(currentOrderInfo)) {
          await this.resumeShipmentSleep({
            scene: 'outofstock',
            msg: this.language.SHEIN_KEY_PWA_34951
          })
        } else {
          SToast(this.language.SHEIN_KEY_PWA_21356)
        }
      } else if (res.code == 666666 || res.code == 777777) {
        SToast(this.language.SHEIN_KEY_PWA_17115)
      } else {
        let hasOwnOrderMsg = res?.info?.fail_list?.find(item => item.billno == billno) || {}
        SToast(hasOwnOrderMsg?.tips || this.language.SHEIN_KEY_PWA_21354)
      }
      setTimeout(() => {
        location.reload()
      }, 1000)
    },
    showRefundPage(product) {
      this.refundItem(product.id)
    },
    async refundItem(id) {
      this.changeRootStatus({
        loading: true
      })
      let res = await schttp({
        method: 'POST',
        url: '/api/orders/base/getRefundGoodsInfo/query',
        data: {
          billno: this.orderInfo.billno,
          order_good_id_list: id,
          apply_scene: 1,
          payment_method: this.orderInfo.payment_method
        },
        headers: { blackbox: window._fmOpt?.__blackbox || '' }
      })
      this.changeRootStatus({
        loading: false
      })

      if (res.code == '0') {
        // 关联退弹框展示
        let resInfo = res?.info || {}
        let isAddOrderList = resInfo?.refund_goods?.filter(item => item.is_added_order == 1)
        let isRiskPunish = resInfo?.refund_goods?.some(item => item?.is_risk_punish === 1)

        if (
          resInfo.promotionBundleFlag > 0
          || isAddOrderList?.length
          || isRiskPunish
        ) {
          this.showRelationDialog = true
          this.relationGoodsList = resInfo?.refund_goods
          this.relationOptions = {
            ...this.relationOptions,
            billno: this.orderInfo.billno,
            cancelInfo: resInfo,
            pageFrom: 'detail',
            type: 1
          }
          this.exposeCombinedRefundTip('1-19-1-76')
        } else {
          this.emitRefundForm(resInfo)
        }
      } else {
        if (res.code == 666666 || res.code == 777777) {
          SToast(this.language.SHEIN_KEY_PWA_17115)
        } else {
          this.showMsg({
            msg: res?.tips || res?.msg || this.language.SHEIN_KEY_PWA_15409
          })
        }
      }
    },
    exposeCombinedRefundTip(type, clickType = '') {
      let mapBillno = this.relationGoodsList?.map(item => item.billno)
      let mapGoodsId = this.relationGoodsList?.map(item => item.order_goods_id)
      let { billno } = this.relationOptions || {}
      let baseParams = {
        order_no: billno,
        relation_billno: mapBillno.join(','),
        relation_goods_id: mapGoodsId.join(',')
      }
      if (clickType) {
        baseParams = { ...baseParams, type: clickType }
      }
      daEventCenter.triggerNotice({
        daId: type,
        extraData: baseParams
      })
    },
    /**
     * @description 初始化部分refundForm数据，用于refund-item页使用
     * @date 2021-09-27 17:28
     * @param {object} info
     */
    emitRefundForm(info) {
      const { orderCurrency } = info || {}
      let refundForm = {
        data: info,
        orderCurrency
      }
      // 更新refundForm
      this.assignCancelItemState({
        visibleMainDrawer: true,
        orderInfo: this.orderInfo,
        language: this.language,
        // 更新refundForm
        refundForm: { ...this.refundForm, ...refundForm },
        // 组件切换
        matchCompName: 'refund-item',
        compStacks: []
      })
    },
    confirmCancelSubmit(cancelInfo = {}) {
      this.showRelationDialog = false
      this.emitRefundForm(cancelInfo)
    },
    updateShowValue() {
      this.showRelationDialog = false
    },
    resumeShipmentSleep(info = {}) {
      return new Promise(resolve => {
        this.resumeShipmentInfo = info
        this.showResumeShipmentDialog = true
        this.asyncCallbacks = [resolve]
      })
    },
    cancleOutOfStockResumeShipment(currentOrderInfo = {}) {
      const {
        order_mark_list: orderMarkList = [],
        order_goods_list: orderGoodsList = []
      } = currentOrderInfo
      const isGoodsMatch = orderGoodsList.some(i => {
        return ![0, 16, 7, 75, 77, 220, 126, 127, 5].includes(i.order_goods_status)
         && i.id != this.currentProduct.id
      })
      return (
        this.abtInfo?.outofStockShowAbt && 
        isGoodsMatch &&
        orderMarkList.some(i => i.mark_type == 1 && i.mark_type_status == 1 && i.trouble_reason == '298')
      )
    }
  }
})
</script>
